import "./index.scss"

function Home() {
  return (
    <div className="header">
        <h1>北科大區塊鏈研究社</h1>
        <h2>社團博覽會活動</h2>

    </div>
  );
}

export default Home;